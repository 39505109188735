define("discourse/plugins/discourse-rss-polling/discourse/controllers/admin-plugins-rss-polling", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/utils", "discourse-common/utils/decorators", "discourse/plugins/discourse-rss-polling/admin/models/rss-polling-feed-settings"], function (_exports, _controller, _object, _computed, _utils, _decorators, _rssPollingFeedSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("valid", "saving"), _dec2 = (0, _decorators.observes)("feedSettings.@each.{feed_url,author_username}"), (_obj = {
    feedSettings: (0, _computed.alias)("model"),
    saving: false,
    valid: false,
    unsavable(valid, saving) {
      return !valid || saving;
    },
    validate() {
      let overallValidity = true;
      this.get("feedSettings").forEach(feedSetting => {
        const localValidity = !(0, _utils.isBlank)(feedSetting.feed_url) && !(0, _utils.isBlank)(feedSetting.author_username);
        (0, _object.set)(feedSetting, "valid", localValidity);
        overallValidity = overallValidity && localValidity;
      });
      this.set("valid", overallValidity);
    },
    actions: {
      create() {
        this.get("feedSettings").addObject({
          feed_url: null,
          author_username: null,
          discourse_category_id: null,
          discourse_tags: null,
          feed_category_filter: null
        });
      },
      destroy(feedSetting) {
        this.get("feedSettings").removeObject(feedSetting);
        this.send("update");
      },
      update() {
        this.set("saving", true);
        _rssPollingFeedSettings.default.update(this.get("feedSettings")).then(updatedSettings => {
          this.set("feedSettings", updatedSettings["feed_settings"]);
        }).finally(() => {
          this.set("saving", false);
        });
      },
      updateAuthorUsername(setting, selected) {
        (0, _object.set)(setting, "author_username", selected.firstObject);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "unsavable", [_dec], Object.getOwnPropertyDescriptor(_obj, "unsavable"), _obj), _applyDecoratedDescriptor(_obj, "validate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "validate"), _obj)), _obj)));
});